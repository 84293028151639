
import Layout from "../../components/layout/Layout";
import { Link, Outlet } from "react-router-dom";
import { RequireLoggedIn } from "../../components/provider/AuthProvider";
import { useTranslation } from "react-i18next";


function Member() {

  const {t} = useTranslation();

  return (
    <>
      <Layout>
        <RequireLoggedIn>
          <div className="flex justify-end gap-x-6 my-6">
            <Link to={`/member/search`} type="button" className="btn btn-sm btn-primary">{t('search')}</Link>
            <Link to={`/member`} type="button" className="btn btn-sm btn-primary">{t('list')}</Link>
            <Link to={`/member/add`} type="button" className="btn btn-sm btn-primary">{t('add')}</Link>
          </div>
          <Outlet />
        </RequireLoggedIn>
      </Layout>
    </>
  );
}

export default Member;
