
import { BRANCHES, PAGE_SIZE } from "./consts";
import axios from "./http"

export const getClient = (accessToken) => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + (accessToken ?? localStorage.getItem("UG_TOKEN"));
    return axios;
}

export const login = async (username, password) => {
    return axios.post(`/oauth/token`, {
        email: username,
        password: password
    });
}

export const getAccessToken = () => {
    return localStorage.getItem("UG_TOKEN");
}

export const saveAccessToken = (token) => {
    localStorage.setItem("UG_TOKEN", token);
}


/**
 * 
 * @returns 
 */
export const addMember = async (user) => {    
    return getClient().post(`api/members`, user);
}

/**
 * 
 * @param {int} pageSize
 * @param {*} page
 * @param {*} branch
 * @returns 
 */
export const getMembers = async (pageSize, page = 1) => {        
    return getClient().get(`api/members?pageSize=${pageSize}&page=${page}`);
}

export const searchMembers = async (mobile, nickname) => {
    return getClient().get(`api/members/search?mobile=${mobile}&nickname=${nickname}`);
}

/**
 * 
 * @param {string} id 
 * @returns 
 */
export const getMember = async (id) => {
    return getClient().get(`api/member/${id}`);
}

/**
 * 
 * @param {string} id 
 * @param {*} data 
 * @returns 
 */
export const updateMember = async (id, data) => {
    return getClient().put(`api/member/${id}`, data);
}

/**
 * 
 * @param {*} id 
 * @returns 
 */
export const deleteMember = async (id) => {
    return getClient().delete(`api/member/${id}`);
}

export const addMemberShip = async (memberId, cardNumber, plan, price, startAt, endAt) => {
    return getClient().post(`api/memberships`, {
        member_id: memberId,
        card_number: cardNumber,
        plan: plan,
        price: price,
        start_at: startAt,
        end_at: endAt
    });
}

export const getMemberByCardNumber = async (cardNumber) => {
    return getClient().get(`api/membership/get/card/${cardNumber}`); 
};

export const getMembershipsOfMember = async (memberId) => {
    return getClient().get(`memberships/${memberId}`);
}

/**
 * 
 * @param {string} branch 
 * @param {string} memberId 
 * @param {string} cardNumber
 * @param {string} name 
 * @param {string} accessAt
 * @param {string} uid
 * @returns 
 */
export const logAccess = async (branch, membershipId, accessType = 'in') => {
    return getClient().post(`api/accesses`, {
        branch: branch,
        membership_id: membershipId,        
        access_type: accessType        
    });
}

export const getAccessLogs = async (pageSize = PAGE_SIZE, page, branch = 'all') => {
    return getClient().get(`api/accesses?pageSize=${pageSize}&page=${page}&branch=${branch}`);
}

/**
 * 
 * @type {{ 
 * name: string, 
 * category: string,
 * price: number,
 * barcode: string,
 * deleted: boolean
 *  }} data
 * @param {*} data 
 * @returns 
 */
export const addProduct = async (data) => {
    return getClient().post(`api/products`, data);
}

export const updateProduct = async (id, data) => {
    return getClient().put(`api/product/${id}`, data);
}

export const getProduct = async (id) => {
    return getClient().get(`api/product/${id}`);
}

/**
 * Call API delete a product
 * 
 * @param {*} id the product id
 * @returns 
 */
export const deleteProduct = async (id) => {    
    return getClient().delete(`api/product/${id}`);
}

/**
 * Get Products list
 * 
 * @param {*} pageSize 
 * @param {*} page 
 * @returns 
 */
export const getProducts = async (pageSize, page) => {
    return getClient().get(`api/products?pageSize=${pageSize}&page=${page}`);
}

/**
 * 
 * Get Product List by category
 * 
 * @param {*} category 
 * @param {*} pageSize 
 * @param {*} page 
 * @returns 
 */
export const getProductsByCategory = async (category, pageSize, page) => {
    return getClient().get(`api/products?category=${category}&pageSize=${pageSize}&page=${page}`);
}

/**
 * 
 * @param {*} branch 
 * @param {*} productId 
 * @param {*} productName 
 * @param {*} price 
 * @param {*} discount 
 * @param {*} paymentMethod 
 * @param {*} uid 
 * @param {*} sellAt 
 * @returns 
 */
export const addSell = async (sale) => {
    return getClient().post(`api/sales`, sale);
}


export const deleteSell = async (id) => {
    return getClient().delete(`api/sale/${id}`);
}

/**
 * Get sells
 * 
 * if the branch is not all then query the specific branch
 * @param {string} branch
 * @param {*} pageSize 
 * @param {*} page
 * @returns 
 */
export const getSells = async (branch, pageSize, page) => {
    return getClient().get(`api/sales?pageSize=${pageSize}&page=${page}&branch=${branch}`);
}

export const getSell = async (id) => {
    return getClient().get(`api/sale/${id}`);
}

/**
 * 
 * @param {int} start 
 * @param {int} end 
 * @returns 
 */
export const getSalesByDate = async (start, end, branch = 'all', page, pageSize) => {
    return getClient().get(`api/sales?start=${start}&end=${end}&branch=${branch}&page=${page}&pageSize=${pageSize}`);
}

export const getBranchSaleAmounts = async  (start, end, branch = 'all') => {
    return getClient().get(`api/reports/amounts?branch=${branch}&startDate=${start}&endDate=${end}`);
}