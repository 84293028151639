
import { useEffect, useState } from "react";
import { searchMembers } from "../../libs/api";
import MemberTableTr from "../../components/member/MemberTableTr";
import { useTranslation } from "react-i18next";

function SearchMember() {

  const [mobile, setMobile] = useState('');

  const [nickname, setNickname] = useState('');

  const [searched, setSearched] = useState(false);


  const [members, setMembers] = useState([]);


  const { t } = useTranslation();

  const handleSearch = () => {
    if (mobile || nickname) {
      searchMembers(mobile, nickname).then((res) => {
        if(res.status === 200) {
          setSearched(true);
          setMembers(res.data);          
        }
      });
    }
  }

    return (
      <>
        <div className="my-6 w-full">
          <h1 className="text-3xl font-bold text-center">{t('title-search-member')}</h1>
          <div className="form-control my-3">
            <label className="label">
              <span className="label-text">{t('mobile')}</span>
            </label>
            <input type="tel" className="input input-bordered" value={mobile} onChange={(e) => setMobile(e.target.value)} onKeyDown={(e) => e.key === 'Enter' && handleSearch()} />
          </div>
          <div className="form-control my-3">
            <label className="label">
              <span className="label-text">{t('nickname')}</span>
            </label>
            <input type="text" className="input input-bordered" value={nickname} onChange={(e) => setNickname(e.target.value)} onKeyDown={(e) => e.key === 'Enter' && handleSearch()} />
          </div>
          <button className="btn btn-primary w-full" onClick={handleSearch}>{t('search')}</button>
        </div>


        <div className="my-6 w-full overflow-x-auto">
          {members.length > 0 && (
            <table className="table table-zebra">
              <thead>
                <tr>
                  <th className="hidden md:table-cell">{t('first-name')}</th>
                  <th className="hidden md:table-cell">{t('last-name')}</th>
                  <th>{t('nickname')}</th>
                  <th>{t('mobile')}</th>
                  <th>{t('language')}</th>
                  <th>{t('join-at')}</th>
                </tr>
              </thead>
              <tbody>
                {members.map(m => (
                  <MemberTableTr key={m.id} member={m} />
                ))}
              </tbody>
            </table>
          )}

          {(members.length === 0 && searched) && (
            <div className="text-2xl font-medium text-center">{t('member-not-found')}</div>
          )}

        </div>
      </>
    );
  }


export default SearchMember;
