export const getDefaultBranch = () => {
    if(localStorage.getItem('branch') !== null){
        return localStorage.getItem('branch');
    }
    return 'laem';
}

export const getBranchName = (branch) => {
    switch(branch){
        case 'laem':
            return 'Laem Chabang';
        case 'pattaya':
            return 'Pattaya';
        default:
            return 'Laem Chabang';
    }
}

export const getDateTimeFromSeconds = (seconds) => {
    return new Date(seconds).toLocaleString();
}

export const getTimeFromSeconds = (seconds) => {
    return new Date(seconds).toLocaleTimeString();
}

export const getLanguageByCode = (code) => {
    switch(code){
        case 'en':        
            return 'English';
        case 'th':
            return 'Thai';
        default:
            return 'English';
    }
}

export const formatCurrency = (amount) => {
    return parseFloat(amount).toLocaleString('th-TH', { style: 'currency', currency: 'THB' });
}

// Bangkok Time in ISO Locale String
export const getBangkokTimeString = () => {
    return new Date().toLocaleString('en-US', { timeZone: 'Asia/Bangkok' });
}

// Bangkok Time in Seconds
export const getBangkokTimeSeconds = () => {
    return new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Bangkok' })).getTime();
}