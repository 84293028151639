import { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import {useTranslation} from "react-i18next";
import {getBranchSaleAmounts} from "../../libs/api";
import { RequireLoggedIn } from "../../components/provider/AuthProvider";
import { formatCurrency } from "../../libs/utils";
import { useNavigate } from "react-router-dom";

function Report(){

    const today = new Date();

    const [startAt, setStartAt] = useState(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, today.getHours(), today.getMinutes(), today.getSeconds()));
    
    const [endAt, setEndAt] = useState(new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours(), today.getMinutes(), today.getSeconds()));        

    const [laemAmount, setLaemAmount] = useState(0);

    const [pattayaAmount, setPattayaAmount] = useState(0);

    const handleCalculate =  () => {        
        // get laem amount
        getBranchSaleAmounts(startAt.getTime() / 1000, endAt.getTime() / 1000).then(res => {
            if(res.status === 200){
                res.data.forEach(data => {
                    if(data.branch === 'laem'){
                        setLaemAmount(data.total);
                    }else{
                        setPattayaAmount(data.total);
                    }
                });
            }
            
        });        
    }

    const {t} = useTranslation();

    const navigator = useNavigate();

    const go = (url) => {
        navigator(url);
    }


    useEffect(() => {
        handleCalculate();
    },[])

    return (
        <Layout>
            <RequireLoggedIn>
            <div className="flex flex-col justify-center gap-y-6">
                <div className="form-control">
                    <label htmlFor="start_at">{t('start-at')}</label>
                    <input type="datetime-local" name="start_at" id="start_id" className="input input-bordered" value={startAt?.toISOString().slice(0, 19)} onChange={(e) => setStartAt(new Date(e.target.value))} />
                </div>
                <div className="form-control">
                    <label htmlFor="start_at">{t('end-at')}</label>
                    <input type="datetime-local" name="start_at" id="start_id" className="input input-bordered" value={endAt?.toISOString().slice(0,19)} onChange={(e) => setEndAt(new Date(e.target.value))} />
                </div>

                <button type="button" className="btn btn-primary" onClick={handleCalculate}>
                    {t('calculate')}
                </button>
            </div>
            <div className="my-16">
                <h2 className="text-xl font-bold text-center">{t('title-sale-amount-report')}</h2>
                <p className="text-lg font-medium text-center">{startAt?.toISOString()} to {endAt?.toISOString()}</p>
                <div className="flex flex-wrap justify-center gap-x-6 gap-y-8 my-8">
                    <div className="card w-96 bg-base-200 shadow-lg cursor-pointer" onClick={() => {
                        go(`/report/sale/laem/${startAt.getTime() / 1000}/${endAt.getTime() / 1000}`)
                    }}>
                        <div className="card-body">
                            <div className="card-title">
                                {t('laem-chabang')}
                            </div>
                            <div>{formatCurrency(laemAmount)}</div>
                        </div>
                    </div>

                    <div className="card w-96 bg-base-200 shadow-lg cursor-pointer" onClick={() => {
                        go(`/report/sale/pattaya/${startAt.getTime() / 1000}/${endAt.getTime() / 1000}`)
                    }}>
                        <div className="card-body">
                            <div className="card-title">
                                {t('pattaya')}
                            </div>
                            <div>{formatCurrency(pattayaAmount)}</div>
                        </div>
                    </div>
                </div>    
            </div>        
            </RequireLoggedIn>
        </Layout>
    )
}

export default Report