import axios from "axios";

const instance = axios.create({
    baseURL: `https://api.universe-gym.com/`,
});

instance.defaults.headers.common["Accept"] = "application/json";
if(localStorage.getItem("UG_TOKEN")){
    instance.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("UG_TOKEN");
}
instance.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
instance.defaults.headers.common["Content-Type"] = "application/json";

instance.interceptors.response.use((response) => {
    return response;

}, (error) => {
    if (error.response.status === 401) {
        // localStorage.removeItem("UG_TOKEN");
        window.location.href = "/login";
    }
    return Promise.reject(error);
});

export default instance;