import { useState } from "react";
import { addProduct } from "../../libs/api";
import {useTranslation} from 'react-i18next';
import { useAuth } from "../../components/provider/AuthProvider";
import { useNavigate } from "react-router-dom";

function AddProduct(props){
    const auth = useAuth();

    

    const [product, setProduct] = useState({
        name: '',
        unit_price: 0,
        category: 'membership',
        cost_price: 0,
        stock: 0,
        barcode: '',        
        // created_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
        // updated_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
        deleted_at: null,
    });
    
    const {t} = useTranslation();
    const navigator = useNavigate();

    const handleSave = async () => {
        // check if all value is not empty
        if (product.name === '' || product.barcode === '' || !product.unit_price || !product.cost_price || product.stock < 0) {
            alert(t('alert-fill-all'));
            return;
        }
        // save new product
        addProduct(product).then(res => {
            if(res.status === 200){
                alert(t('alert-add-product-success'));
                // clear form
                navigator(`/product/${res.data.id}`);
            }else{
                alert(t('alert-add-product-failed'));
            }
        });
    }

    return <>
        <div className="flex flex-col gap-y-6 justify-center">
            <h1 className="text-3xl font-extrabold my-8">{t('title-add-product')}</h1>
            <div className="form-control">
                <label htmlFor="category">
                    <span>{t('category')}</span>
                </label>
                <select name="category" id="category" className="input input-bordered" value={product.category} onChange={(e) => setProduct({ ...product, category: e.target.value })}>
                    <option value="membership">{t('membership')}</option>
                    <option value="snack">{t('snack')}</option>
                    <option value="beverage">{t('beverage')}</option>
                    <option value="accessories">{t('accessories')}</option>
                </select>
            </div>
            <div className="form-control">
                <label htmlFor="name">
                    <span className="label-text">{t('name')}</span>
                </label>
                <input type="text" name="name" id="name" className="input input-bordered" placeholder={t('name')} value={product.name} onChange={(e) => setProduct({ ...product, name: e.target.value })} />
            </div>
            <div className="form-control">
                <label htmlFor="unit_price">
                    <span className="label-text">{t('price')}</span>
                </label>
                <input type="number" name="unit_price" id="unit_price" className="input input-bordered" placeholder="Price" value={product.unit_price} onChange={(e) => setProduct({ ...product, unit_price: e.target.value })} />
            </div>            
            <div className="form-control">
                <label htmlFor="cost_price">
                    <span className="label-text">{t('cost-price')}</span>
                </label>
                <input type="number" name="cost-price" id="cost-price" className="input input-bordered" placeholder={t('cost-price')} value={product.cost_price} onChange={(e) => setProduct({ ...product, cost_price: e.target.value })} />
            </div>
            <div className="form-control">
                <label htmlFor="stock">
                    <span className="label-text">{t('stock')}</span>
                </label>
                <input type="number" name="stock" id="stock" className="input input-bordered" placeholder={t('stock')} value={product.stock} onChange={(e) => setProduct({ ...product, stock: e.target.value })} />
            </div>
            <div className="form-control">
                <label htmlFor="barcode">
                    <span className="label-text">{t('barcode')}</span>
                </label>
                <input type="number" name="barcode" id="barcode" className="input input-bordered" placeholder={t('barcode')} value={product.barcode} onChange={(e) => setProduct({ ...product, barcode: e.target.value })} />
            </div>

            <button className="btn btn-primary" onClick={handleSave}>{t('btn-add-product')}</button>
        </div>
    </>
}

export default AddProduct;