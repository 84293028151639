import { persistentAtom } from "@nanostores/persistent";

export const currentUser = persistentAtom('us-current-user', {}, {
    encode: (value) => {
        if(value === null){
            value = {};
        }
        return JSON.stringify(value)
    },
    decode: (value) => {
        try{
            return JSON.parse(value);
        }catch(e){
            return {}
        }
    },
});

/**
 * Save User data
 * @param {*} u 
 */
export const saveUser = (u) => {
    currentUser.set(u);
}

/**
 * Clean user data
 */
export const cleanUser = () => {
    currentUser.set(null);
    localStorage.removeItem('UG_TOKEN');
}