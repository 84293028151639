import { useEffect, useState } from "react";
import { getMembers } from "../../libs/api";
import MemberTableTr from "../../components/member/MemberTableTr";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { getLanguageByCode } from "../../libs/utils";

function ListMember() {
  const [members, setMembers] = useState([]);

  const [page, setPage] = useState(1);

  const [pager, setPager] = useState({});

  const pageSize = 10;

  const { t } = useTranslation();

  const navigator = useNavigate();

  const loadPageData = (pageSize, page) => {
    getMembers(pageSize, page).then((data) => {      
      setMembers(data.data.data);
      setPager(data.data.pager);
    });
  };

  const go = (id) => {
    navigator(`/member/${id}`);
  };

  useEffect(() => {
    loadPageData(pageSize, page);
  }, [page]);

  return (
    <>
      <div className="my-6 w-full overflow-x-auto">
        <table className="table table-zebra w-full">
          <thead>
            <tr>
              <th className="hidden md:table-cell">{t("first-name")}</th>
              <th className="hidden md:table-cell">{t("last-name")}</th>
              <th>{t("nickname")}</th>
              <th>{t("mobile")}</th>
              <th>{t("language")}</th>
              <th>{t("join-at")}</th>
            </tr>
          </thead>
          <tbody>
            {members.map((member, index) => (              
                <tr key={index} onClick={() => go(member.id)} className="cursor-pointer">
                  <td className="hidden md:table-cell cursor-pointer">
                    {member.first_name}
                  </td>
                  <td className="hidden md:table-cell">{member.last_name}</td>
                  <td>{member.nickname}</td>
                  <td>{member.phone}</td>
                  <td>{getLanguageByCode(member.language)}</td>
                  <td>{member.join_at}</td>
                </tr>              
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex flex-col my-3 justify-center items-center">
            <div className="my-3">
                <span>Current: {pager.perPage * (page -1)} - {pager.perPage * page} / Total: {pager.total}</span>
            </div>
            <div className="flex gap-x-8">
                <button className="btn btn-info" onClick={() => setPage(page - 1)}>{t('prev')}</button>
                <button className="btn btn-info" onClick={() => setPage(page + 1)}>{t('next')}</button>
            </div>            
      </div>
    </>
  );
}

export default ListMember;
