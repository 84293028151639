import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getProducts, getProductsByCategory } from "../../libs/api";
import {useTranslation} from 'react-i18next';

function MenuItem(props){

    const {category} = useParams();


    const [products, setProducts] = useState([]);
    
    const [page, setPage] = useState(1);

    const navigator = useNavigate();

    const {t} = useTranslation();

    useEffect(() => {
        getProductsByCategory(category, 5,page).then(res => {
            console.log(res);
            if(res.status === 200){
                setProducts(res.data.data);
            }
        });
    }, [category, page]);

    return <>        
        <div className="flex flex-col justify-center items-center">
            <h1 className="text-3xl font-extrabold my-8">{t('select-product')}</h1>

            {products.length === 0 && <div className="text-2xl font-bold">{t('no-product')}</div>}

            {products.length > 0 && (
                <div className="flex flex-wrap gap-x-6 gap-y-8 justify-center">
                {products.map((data, index) => {                    
                    return (
                        <div className="card bg-base-200 shadow-2xl w-36 md:w-60 cursor-pointer" onClick={() => {
                            navigator(`/sale/add/${data.id}`)
                        }} key={index}>
                        <div className="card-body">
                            <div className="card-title">
                                <h1>{data.name}</h1>
                            </div>
                            {data.unit_price}
                            <div className="badge badge-info badge-sm">
                            {data.barcode}
                            </div>
                        </div>
                        </div>
                    )
                })}                
            </div>
            )}
            

        </div>
    </>
}

export default MenuItem;