import { t } from "i18next";
import { useEffect, useState } from "react";
import { getProduct, deleteProduct } from "../../libs/api";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { formatCurrency } from "../../libs/utils";

function ViewProduct(props){
    
    const {id} = useParams();

    const [product, setProduct] = useState({});

    const {t} = useTranslation();

    const navigator = useNavigate();


    useEffect(() => {
        getProduct(id).then(res => {
            if(res.data.data){
                setProduct(res.data.data);
            }
        });
    }, []);

    
    const handleDelete = () => {
        if(window.confirm(t('alert-product-deleted'))){
            deleteProduct(id).then(() => {
                alert(t('message-product-deleted'));
                navigator('/product');
            });
        }
    }

    const handleEdit = () => {
        navigator('/product/edit/' + id);
    }

    return <>                            
            <div className="my-8 mx-auto">
            <h1 className="text-3xl font-extrabold my-8">{t('title-product-view')}</h1>    
                <dl>
                    <dt>{t('product-name')}</dt>
                    <dd>{product.name}</dd>
                    <dt>{t('price')}</dt>
                    <dd>{formatCurrency(product.unit_price)}</dd>
                    <dt>{t('cost_price')}</dt>
                    <dd>{formatCurrency(product.cost_price)}</dd>
                    <dt>{t('category')}</dt>
                    <dd>{t(product.category)}</dd>
                    <dt>{t('barcode')}</dt>
                    <dd>{product.barcode}</dd>
                    <dt>{t('deleted')}</dt>
                    <dd>{product.deleted_at ? t('yes') : t('no')}</dd>
                </dl>
            </div>
            <div className="my-8 flex justify-center gap-x-6">
                <button type="button" className="btn btn-error" onClick={handleDelete}>{t('delete')}</button>
                <button type="button" className="btn btn-warning" onClick={handleEdit}>{t('edit')}</button>
            </div>
        
    </>
}

export default ViewProduct;