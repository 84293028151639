import Layout from "../components/layout/Layout";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { cleanUser } from "../stores/userStore";

function Settings(){

    const [branch, setBranch] = useState('laem');

    

    const {t, i18n} = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    const navigator = useNavigate();
    
    const handleSave = () => {
        localStorage.setItem('branch', branch);
        // https://www.npmjs.com/package/i18next-browser-languagedetector
        i18n.changeLanguage(language);
        alert(t('alert-save-success'));
    }

    const handleLogout = () => {
        if(window.confirm(t('alert-logout'))) {
             cleanUser(); 

             navigator(`/login`);
        }
    }

    const handleClearData = () => {
        if(window.confirm(t(`alert-clear-data`))){
            localStorage.clear();
            alert(t('alert-clear-data-success'));
        }
    }

    return (
        <>
        <Layout>
            <div className="flex flex-col justify-center my-8 gap-y-8">
            <div className="form-control">
                <label className="label">
                    <span className="label-text">{t('default-branch')}</span>
                </label>
                <select className="select select-bordered" value={branch} onChange={(e) => setBranch(e.target.value)}>
                    <option value="laem">{t('laem-chabang')}</option>
                    <option value="pattaya">{t('pattaya')}</option>
                </select>                
            </div>

            <div className="form-control">
                <label className="label">
                    <span className="label-text">{t('default-language')}</span>
                </label>
                <select className="select select-bordered" value={language} onChange={(e) => setLanguage(e.target.value)}>
                    <option value="en">{t('english')}</option>
                    <option value="th">{t('thai')}</option>
                </select>                
            </div>

            <button type="button" className="btn btn-primary" onClick={handleSave}>{t('save')}</button>

            <button type="button" className="btn btn-warning" onClick={handleClearData}>{t('btn-clear-data')}</button>

            <button type="button" className="btn btn-warning" onClick={handleLogout}>{t('btn-logout')}</button>

            </div>

            
        </Layout>
        </>
    )
}

export default Settings;