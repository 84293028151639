import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import categories from "../../data/categories.json";
import { Icon } from "@iconify/react";

function MenuCategory(props) {

    const navigator = useNavigate();

    const { t } = useTranslation();

    return <>
        <div className="flex flex-col justify-center items-center">
            <h1 className="text-3xl font-extrabold my-8">{t('select-product')}</h1>
            <div className="flex flex-wrap gap-x-6 gap-y-8 justify-center">
                {categories.map((category, index) => (
                    <div className="card card-side bg-base-200 shadow-lg w-96 cursor-pointer" onClick={() => {
                        navigator(`/sale/category/${category.slug}`)
                    }} key={index}>
                        <figure className="p-5">
                            <Icon icon={category.icon} className="w-20 h-20" />
                        </figure>
                        <div className="card-body">
                            <div className="card-title">
                                <h1>{category.name}</h1>
                            </div>
                            {category.description}
                        </div>
                    </div>

                ))}

            </div>
        </div>
    </>
}

export default MenuCategory;