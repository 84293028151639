import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider, } from 'react-router-dom';
import './index.css';
import Access from './pages/access/Access';
import ListAccess from './pages/access/ListAccess';
import AddAccess from './pages/access/AddAccess';

import App from './pages/App';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import AddMember from './pages/member/AddMember';
import AddMembership from './pages/member/AddMembership';
import ListMember from './pages/member/ListMember';
import Member from './pages/member/Member';
import ViewMember from "./pages/member/ViewMember";

import reportWebVitals from './reportWebVitals';
import Product from './pages/product/Product';
import AddProduct from './pages/product/AddProduct';

import ListProduct from './pages/product/ListProduct';
import Sell from './pages/sell/Sell';
import ListSell from './pages/sell/ListSell';
import AddSell from './pages/sell/AddSell';

import "./libs/i18n"
import Settings from './pages/setting';
import Report from './pages/report/Report';
import ViewProduct from './pages/product/ViewProduct';
import ViewSell from './pages/sell/ViewSell';
import MenuCategory from './pages/sell/MenuCategory';
import { AuthProvider } from './components/provider/AuthProvider';
import MenuItem from './pages/sell/MenuItem';
import EditProduct from './pages/product/EditProduct';
import EditMember from './pages/member/EditMember';
import SearchMember from './pages/member/SearchMember';
import SellReport from './pages/report/SellReport';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/dashboard",
    element: <Dashboard />
  },
  {
    path: "/member",
    element: <Member/>,
    children: [
      {
        index: true,
        element: <ListMember/>
      },
      {
        path: "add",
        element: <AddMember/>
      },
      {
        path: "search",
        element: <SearchMember/>
      },
      {
        path: ":id",
        element: <ViewMember/>
      },
      {
        path: ":id/edit",
        element: <EditMember/>
      },
      {
        path: ":id/plan/add",
        element: <AddMembership/>
      }
    ]
  },  
  {
    path: "/access",
    element: <Access/>,
    children: [
      {
        index: true,
        element: <ListAccess/>
      },
      {
        path: 'add',
        element: <AddAccess/>
      }
    ]
  },{
    path: "/product",
    element: <Product/>,
    children: [
      {
        index: true,
        element: <ListProduct/>
      },
      {
        path: 'add',
        element: <AddProduct/>
      },
      {
        path: ':id',
        element: <ViewProduct/>
      },
      {
        path: 'edit/:id',
        element: <EditProduct/>
      }
    ]
  },
  {
    path: "/sale",
    element: <Sell/>,
    children: [
      {
        index: true,
        element: <MenuCategory/>
      },
      {
        path: 'category/:category',
        element: <MenuItem/>
      },      
      {
        path: 'add/:id',
        element: <AddSell/>
      },
      {
        path: 'list',
        element: <ListSell/>
      },
      {
        path: ':id',
        element: <ViewSell/>
      }
    ]
  },
  {
    path: "/settings",
    element: <Settings/>
  },
  {
    path: "/report",
    element: <Report />
  },
  {
    path: "/report/sale/:branch/:start/:end",
    element: <SellReport />
  }
]);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
    <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
