import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../components/provider/AuthProvider";
import { getMember, addMemberShip } from "../../libs/api";

function AddMembership() {
    

    const {id} = useParams();
    
    const [member, setMember] = useState({});

    const navigator = useNavigate();

    const {t} = useTranslation();

    useEffect(() => {
        // get member from firestore
        getMember(id).then(res => {
            if(res.status === 200){
                setMember(res.data);
            }else{
                alert(t('member-not-found'));
            }            
        });

        // check if on membership already
    }, []);

    const [membership, setMembership] = useState({
        card_number: '',
        plan_name: '1m',
        plan_price: '',
        start_at: new Date().toISOString().slice(0, 10),
        end_at: new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString().slice(0, 10)        
    });

    const handleSave = async () => {
        
        // check if all value is not empty
        if (membership.plan_name === '' || membership.plan_price === '' || membership.start_at === '' || membership.end_at === '') {
            alert(t('alert-fill-all'));
            return;
        }
        // save user to firebase
        
        const {card_number, plan_name, plan_price, start_at, end_at} = membership;

        addMemberShip(id,card_number, plan_name, plan_price, start_at, end_at).then(res => {
            if(res.status === 200){
                alert(t('message-membership-added-success'));
                // navigate to member detail page
                navigator(`/member/${id}`);
            }else{
                alert(t('message-membership-added-fail'));
            }
        });   
    }
    return (
        <>
            <div className="flex flex-col justify-center gap-y-6">
                <h1 className="text-3xl font-extrabold text-center">{t('btn-add-membership')}</h1>
                <div className="text-2xl font-bold text-center">{member.nickname}</div>
                {member.first_name && (
                    <p className="text-center">{member.first_name} {member.last_name}</p>
                )}

                <div className="form-control">
                    <label htmlFor="card_number" className="label">
                        <span className="label-text">{t('card-number')}</span>
                    </label>
                    <input type="text" name="card_number" id="card_number" className="input input-bordered" placeholder={t('card-number')} value={membership.card_number} onChange={(e) => setMembership({ ...membership, card_number: e.target.value })} />
                </div>

                <div className="form-control">
                    <label htmlFor="plan_name" className="label">
                        <span className="label-text">{t('membership-plan-name')}</span>
                    </label>
                    <select name="plan_name" id="plan_name" className="select select-bordered" value={membership.plan_name} onChange={(e) => setMembership({ ...membership, plan_name: e.target.value })}>
                        <option value="1m">1 Month</option>
                        <option value="1mc">1 Month Couple</option>
                        <option value="3m">3 Months</option>
                        <option value="3mc">3 Months Couple</option>
                        <option value="6m">6 Months</option>
                        <option value="6mc">6 Months Couple</option>
                        <option value="12m">12 Months</option>
                        <option value="12mc">12 Months Couple</option>
                        <option value="10d">10 Days Access</option>
                        <option value="30d">30 Days Access</option>
                    </select>
                </div>

                <div className="form-control">
                    <label htmlFor="plan_price" className="label">
                        <span className="label-text">{t('membership-plan-price')}</span>
                    </label>
                    <input type="number" name="plan_price" id="plan_price" className="input input-bordered" placeholder="Plan Price" value={membership.plan_price} onChange={(e) => setMembership({ ...membership, plan_price: e.target.value })} />
                </div>



                <div className="form-control">
                    <label htmlFor="start_at" className="label">
                        <span className="label-text">{t('start-at')}</span>
                    </label>
                    <input type="date" name="start_at" id="start_at" className="input input-bordered" placeholder="Start At" value={membership.start_at} onChange={(e) => setMembership({ ...membership, start_at: e.target.value })} />
                </div>

                <div className="form-control">
                    <label htmlFor="end_at" className="label">
                        <span className="label-text">{t('end-at')}</span>
                    </label>
                    <input type="date" name="end_at" id="end_at" className="input input-bordered" placeholder="End At" value={membership.end_at} onChange={(e) => setMembership({ ...membership, end_at: e.target.value })} />
                </div>

                <button type="button" className="btn btn-primary" onClick={handleSave}>{t('btn-add-membership')}</button>
            </div>
        </>
    )
}

export default AddMembership;