// Description: This file contains all the constants used in the application

export const APP_NAME = 'Universe Gym';

export const BRANCH_LAEM = 'laem';

export const BRANCH_PATTAYA = 'pattaya';

export const BRANCHES = [BRANCH_LAEM, BRANCH_PATTAYA];

export const PAGE_SIZE = 10;

export const CATEGORY_MEMBERSHIP = 'membership';

export const CATEGORY_SNACK = 'snack';

export const CATEGORY_DRINK = 'drink';

export const CATEGORY_ACCESSORIES = 'accessories';

