import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

function LinkCard(props){
    const navigator = useNavigate();

    const go = (path) => {
        navigator(path);
    }

    return (
        <>
            <div className={`card card-side w-96 bg-base-200 hover:cursor-pointer ${props.className}`} onClick={() => go(props.path)}>
                {props.icon && (
                    <figure className="p-5">
                        <Icon icon={props.icon} className="w-20 h-20" />
                    </figure>
                )}
                <div className="card-body">
                    <div className="card-title">
                    {props.title}
                    </div>
                    {props.content}
                </div>
            </div>
        </>
    )
}

export default LinkCard;