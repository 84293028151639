import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { getMember, deleteMember } from '../../libs/api';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import { useAuth } from '../../components/provider/AuthProvider';
import { formatCurrency, getLanguageByCode } from '../../libs/utils';

function ViewMember(props){    

    const auth = useAuth();

    const {id} = useParams();

    const [member, setMember] = useState({
        first_name: '',
        last_name: '',
        nickname: '',
        mobile: '',
        join_at: '',
        language: ''
    });

    const [membership, setMembership] = useState([]);

    const {t} = useTranslation();

    const navigator = useNavigate();

    const handleDelete = () => {        
        if(window.confirm(t('confirm-delete-member'))){
            deleteMember(id).then((res) => {
                if(res.status === 200){
                    alert(t('alert-member-deleted-success'));
                    navigator('/member');
                }else{
                    alert(t('alert-member-deleted-fail'));
                }
                
            });
        }
    }

    const handleEdit = () => {
        navigator(`/member/${id}/edit`);
    }

    useEffect(() => {        
        getMember(id).then(res => {            
            if(res.status === 200){
                console.log(res.data);
                setMember(res.data);
                setMembership(res.data.memberships);
                
            }else{
                alert(t('member-not-found'));     
            }
        });
    }, [id]);

    return <>
        <div className="flex flex-col justify-center items-center">
            <div className='my-3'>            
                <button type='button' className='btn btn-error btn-sm mr-2' onClick={handleDelete}>{t('btn-delete-member')}</button>

                <button type='button' className='btn btn-warning btn-sm' onClick={handleEdit}>{t('btn-edit-member')}</button>
            </div>
            <h1 className="text-3xl font-extrabold my-8">{t('view-member')} : {member.first_name ?? member.nickname}</h1>
            <div>
                <dl>
                    <dt>{t('first-name')}</dt>
                    <dd>{member.first_name}</dd>
                    <dt>{t('last-name')}</dt>
                    <dd>{member.last_name}</dd>
                    <dt>{t('nickname')}</dt>
                    <dd>{member.nickname}</dd>
                    <dt>{t('mobile')}</dt>
                    <dd>{member.phone}</dd>
                    <dt>{t('join-at')}</dt>
                    <dd>{member.join_at}</dd>
                    <dt>{t('language')}</dt>
                    <dd>{getLanguageByCode(member.language)}</dd>
                </dl>
            </div>

            <div className="w-full flex flex-col justify-center items-center my-6">
                <h2 className="text-2xl font-bold my-3">{t('membership')}</h2>
                {membership.length === 0 && <div className="text-xl">{t('no-membership-yet')}</div>}
                
                <div className="overflow-x-auto w-full  my-3">
                {membership.length > 0 && (
                    
                    <table className="table table-zebra">
                    <thead>
                        <tr>
                            <th>{t('card')}</th>
                            <th>{t('plan')}</th>
                            <th>{t('price')}</th>
                            <th>{t('start-at')}</th>
                            <th>{t('end-at')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {membership.map((data, index) => {                            
                            return <tr key={index}>
                                <td>{data.card_number}</td>
                                <td>{data.plan_name}</td>
                                <td>{formatCurrency(data.price)}</td>
                                <td>{data.start_date}</td>
                                <td>{data.end_date}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
                
                )}
                </div>
                                
                <Link to={`/member/${id}/plan/add`} className="btn btn-primary my-6">{t('btn-add-membership')}</Link>                
            </div>            
        </div>
    </>
}

ViewMember.propTypes = {
    id: PropTypes.number
}

export default ViewMember;