import { Link, Outlet } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import { RequireLoggedIn } from "../../components/provider/AuthProvider";
import { useTranslation } from "react-i18next";

function Sell(props) {
  const { t } = useTranslation();

  return (
    <>
      <Layout>
        <RequireLoggedIn>
          <div className="flex justify-end gap-x-6 my-6">
            <Link
              to={`/sale/list`}
              type="button"
              className="btn btn-sm btn-primary"
            >
              {t("list")}
            </Link>
          </div>
          <Outlet />
        </RequireLoggedIn>
      </Layout>
    </>
  );
}
export default Sell;
