import { useEffect } from "react";
import Layout from "../components/layout/Layout";
import LinkCard from "../components/layout/LinkCard";
import { useTranslation } from "react-i18next";
import { RequireLoggedIn, useAuth } from "../components/provider/AuthProvider";

function Dashboard(){
    

    const {t} = useTranslation();

    return (
        <>
            <Layout>
                <RequireLoggedIn>
                <div className="flex flex-wrap justify-center items-center gap-x-6 gap-y-8 my-8">
                    <LinkCard path={`/sale`} title={t('sales')} icon="material-symbols:point-of-sale"
                    content={t('manage-sales')}/>

                    <LinkCard path={`/member`} title={t('members')} content={t('manage-members')} icon="ph:users-three"/>

                    <LinkCard path={`/access`} title={t('access')} content={t('manage-access')} icon="octicon:log-24"/>

                    <LinkCard path={`/product`} title={t('products')} content={t('manage-products')} icon="fluent-mdl2:product-variant"/>

                    <LinkCard path={`/report`} title={t('reports')} content={t('view-reports')} icon="mdi:report-bar"/>
                    
                </div>
                </RequireLoggedIn>
            </Layout>                    
        </>        
    );
}

export default Dashboard;