import { t } from "i18next";
import { useEffect, useState } from "react";
import { getProducts } from "../../libs/api";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "../../libs/utils";
import { PAGE_SIZE } from "../../libs/consts";

function ListProduct(props){
    const [products, setProducts] = useState([]);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const [page, setPage] = useState(1);
    const [pager, setPager] = useState({});

    useEffect(() => {
        getProducts(pageSize, page).then((data) => {
            console.log(data);
            setProducts(data.data.data);
            setPager(data.data.pager);
        }).catch((err) => {
            console.log(err);
        });

    }, [page]);

    const {t} = useTranslation();

    const navigator = useNavigate();

    return <>
        <div className="flex flex-col justify-center items-center">
            <h1 className="text-3xl font-extrabold my-8">{t('products-list')}</h1>            
            <div className="w-full my-8 overflow-x-auto">
                <table className="w-full table table-zebra">
                    <thead>
                        <tr>
                            <th>{t('product-name')}</th>
                            <th>{t('category')}</th>
                            <th>{t('price')}</th>
                            <th>{t('barcode')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((item, index) => {                            
                            return <tr key={index} className="cursor-pointer" onClick={() => navigator(`/product/${item.id}`)}>
                                <td>{item.name}</td>
                                <td>{t(item.category)}</td>
                                <td>{formatCurrency(item.unit_price)}</td>
                                <td>{item.barcode}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            
        </div>
        <div className="flex flex-col my-3 justify-center items-center">
            <div className="my-3">
                <span>Current: {pager.perPage * (page -1)} - {pager.perPage * page} / Total: {pager.total}</span>
            </div>
            <div className="flex gap-x-8">
                <button className="btn btn-info" onClick={() => setPage(page - 1)}>{t('prev')}</button>
                <button className="btn btn-info" onClick={() => setPage(page + 1)}>{t('next')}</button>
            </div>            
        </div>
    </>
}

export default ListProduct;