import { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { useTranslation } from "react-i18next";
import { getSalesByDate } from "../../libs/api";
import { RequireLoggedIn } from "../../components/provider/AuthProvider";
import {
  formatCurrency,
  getBranchName,
  getTimeFromSeconds,
} from "../../libs/utils";
import { useParams } from "react-router-dom";

function SellReport() {
  const { branch, start, end } = useParams();

  const [startAt, setStartAt] = useState(start);

  const [endAt, setEndAt] = useState(end);

  console.log(start,end);

  const [sales, setSales] = useState([]);

  const [page, setPage] = useState(1);

  const [pageSize, setPageSize] = useState(10);

  const [pager, setPager] = useState({});

  const { t } = useTranslation();

  useEffect(() => {
    getSalesByDate(startAt, endAt, branch, page, pageSize).then((res) => {
      console.log(res);
      if (res.status === 200) {
        setSales(res.data.data);
        setPager(res.data.pager);
      } else {
        alert(res.message);
      }
    });
  }, [page, pageSize]);

  return (
    <Layout>
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-3xl font-extrabold my-3">Sale Report {getBranchName(branch)}</h1>
        <p className="my-3">
            {new Date(startAt * 1000).toISOString()} - {new Date(endAt * 1000).toLocaleDateString()}
        </p>
        {sales && sales.length > 0 && (
          <>
            <div className="overflow-x-auto w-full">
              <table className="w-full table table-zebra">
                <thead>
                  <tr>
                    <th>{t("branch")}</th>
                    <th>{t("total")}</th>
                    <th>{t("time")}</th>
                  </tr>
                </thead>
                <tbody>
                  {sales.map((item, index) => {
                    return (
                      <tr
                        className="cursor-pointer"
                        key={index}
                        onClick={() => navigator(`/sale/${item.id}`)}
                      >
                        <td>{getBranchName(item.branch)}</td>
                        <td>{formatCurrency(item.total)}</td>
                        <td>{getTimeFromSeconds(item.created_at)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="flex flex-col my-3 justify-center items-center">
              <div className="my-3">
                <span>
                  Current: {pager.perPage * (page - 1)} - {pager.perPage * page}{" "}
                  / Total: {pager.total}
                </span>
              </div>
              <div className="flex gap-x-8">
                <button
                  className="btn btn-info"
                  onClick={() => setPage(page - 1)}
                >
                  {t("prev")}
                </button>
                <button
                  className="btn btn-info"
                  onClick={() => setPage(page + 1)}
                >
                  {t("next")}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      </Layout>
  );
}

export default SellReport;
