
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthProvider } from "../components/provider/AuthProvider";
import { cleanUser, saveUser } from "../stores/userStore";
import { login, saveAccessToken } from "../libs/api";
import { getMembers } from "../libs/api";

/**
 * Process Login
 * @returns 
 */
function Login(){        
    
    const navigator = useNavigate();

    

    const [email, setEmail] = useState('');

    const [password, setPassword] = useState('');

    const loginAvailable = useMemo(() => {
        return email.length > 0 && password.length > 0;
    }, [email, password]);

    const {t} = useTranslation();

    useEffect(() => {
        

    }, []);

    const handleLogin = async () => {
        login(email, password).then((response) => {
            console.log(JSON.stringify(response));
            
            cleanUser();
            saveAccessToken(response.data.token);
            saveUser({
                'id': email,
                'token': response.data.token
            });
            navigator('/dashboard');
        }).catch((error) => {
            console.log(error);
            cleanUser();
        });
    }

    return (
        <>
        <AuthProvider>
            <div className="w-screen h-screen flex flex-col justify-center items-center">
                <div className="flex flex-col justify-center px-8 md:px-16">
                <h1 className="text-3xl font-extrabold self-center my-3">{t('login')}</h1>
                <p className="my-3">{t('login-tip')}</p>
                <input type="email" name="email" id="email" className="input input-bordered my-3" placeholder={t('email')} value={email} onChange={(e) => setEmail(e.target.value)} />
                <input type="password" name="password" id="password" className="input input-bordered my-3" placeholder={t('password')} value={password} onChange={(e) => setPassword(e.target.value)} />
                <div className="my-3 flex gap-6">
                    
                    <button type="button" className="btn btn-primary" disabled={!loginAvailable} onClick={handleLogin}>{t('login')}</button>
                </div>
                </div>            
            </div>
        </AuthProvider>
        </>
    )
}

export default Login;