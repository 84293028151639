import { useTranslation } from "react-i18next";
import { Link, Outlet } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import { RequireLoggedIn } from "../../components/provider/AuthProvider";

function Product(props){

    const {t} = useTranslation();

    return <>
    <Layout>
        <RequireLoggedIn>
        <div className="flex justify-end gap-x-6 my-6">         
            <Link to={`/product`} type="button" className="btn btn-sm btn-primary">{t('list')}</Link>
            <Link to={`/product/add`} type="button" className="btn btn-sm btn-secondary">{t('add')}</Link>
        </div>
        <Outlet />   
        </RequireLoggedIn>     
      </Layout>
    </>
}
export default Product;