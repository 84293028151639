import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { getDateTimeFromSeconds, getLanguageByCode } from "../../libs/utils";

function MemberTableTr(props) {
    
  const id = props.member.id;
  const member = props.member;
  
  const navigator = useNavigate();

  const go = () => {
    navigator(`/member/${id}`);
  }

  
  return (    
      <tr onClick={go}>
        <td className="hidden md:table-cell cursor-pointer">
        {member.first_name}
        </td>
        <td className="hidden md:table-cell">
        {member.last_name}
        </td>        
        <td>{member.nickname}</td>
        <td>{member.phone}</td>        
        <td>{getLanguageByCode(member.language)}</td>
        <td>{member.join_at}</td>
      </tr>    
  );
}

MemberTableTr.propTypes = {
  member: PropTypes.object.isRequired
}

export default MemberTableTr;
