import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


function App() {

  const {t} = useTranslation();

  const navigator = useNavigate();

  const go = (path) => {
    navigator(path);
  }

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center">
      <h1 className="text-4xl font-extrabold my-8">Universe Gym</h1>
      <div className="flex flex-col gap-y-8">
        <button type="button" className="btn btn-primary" onClick={() => go('/dashboard')}>{t('dashboard')}</button>
        <button type="button" className="btn btn-secondary" onClick={() => go('/login')}>{t('login')}</button>
      </div>
    </div>
  );
}

export default App;
