import { t } from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../components/provider/AuthProvider";
import { getSell, deleteSell } from "../../libs/api";
import { getBranchName, getTimeFromSeconds } from "../../libs/utils";

function ViewSell(props) {
  const { id } = useParams();

  const auth = useAuth();

  const [sale, setSale] = useState(null);

  const { t } = useTranslation();

  const navigator = useNavigate();

  const handleDelete = () => {
    if (window.confirm(t("alert-confirm-delete-sale"))) {
      deleteSell(id).then(() => {
        alert(t("alert-sale-deleted-success"));
        navigator("/sale");
      });
    }
  };

  useEffect(() => {
    getSell(id).then((res) => {
      console.log(res);
      setSale(res.data.data);
    });
  }, []);

  return (
    <>
      <div className="my-8 flex flex-col items-center">
        <h1 className="text-3xl font-extrabold my-3">{t("sales-last")}</h1>
        <dl>
          <dt>{t("branch")}</dt>
          <dd>{getBranchName(sale?.branch)}</dd>

          <dt>{t("total")}</dt>
          <dd>{sale?.total} ฿</dd>
          <dt>{t("discount")}</dt>
          <dd>{sale?.discount ?? 0} ฿</dd>
          <dt>{t("time")}</dt>
          <dd>{sale?.created_at}</dd>
        </dl>
      </div>
      {sale?.items && sale.items.length > 0 && (
        <div className="w-full overflow-x-auto">
          <table className="w-full table table-zebra">
            <thead>
              <tr>
                <th>{t("product-name")}</th>
                <th>{t("quantity")}</th>
                <th>{t("unit-price")}</th>
              </tr>
            </thead>
            <tbody>
              {sale?.items &&
                sale.items.map((item, index) => (
                  <tr>
                    <td>{item.name}</td>

                    <td>{item.quantity}</td>

                    <td>{item.unit_price} ฿</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="flex justify-end">
        <button type="button" className="btn btn-error" onClick={handleDelete}>
          {t("delete")}
        </button>
      </div>
    </>
  );
}

export default ViewSell;
