import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../components/provider/AuthProvider";
import { getMemberByCardNumber, logAccess } from "../../libs/api";
import { getDefaultBranch } from "../../libs/utils";

function AddAccess() {

    const auth = useAuth();

    const [isLoaded, setIsLoaded] = useState(false);

    const [member, setMember] = useState(null);

    const [memberId, setMemberId] = useState(null);

    const [cardNumber, setCardNumber] = useState('');

    const [branch, setBranch] = useState(getDefaultBranch());

    const [accessAt, setAccessAt] = useState(new Date());

    const { t } = useTranslation();

    const handleFind = async () => {
        // get member from firestore        
        getMemberByCardNumber(cardNumber).then(res => {
            console.log(res);
            if(res.status === 200){
                if(res.data.status === 'active'){
                    setMemberId(res.data.id);
                    setMember(res.data.member);
                    setIsLoaded(true);
                }else{
                    alert(t('member-not-found'));
                    setIsLoaded(false);
                }
            }else{
                alert(t('membership-expired'));
                setIsLoaded(false);
            }

        });        
    }

    const handleLogAccess = async (type) => {
        // check if all value is not empty
        if (cardNumber === '' || branch === '' || accessAt === '') {
            alert(t('alert-fill-all'));
            return;
        }
        logAccess(branch, memberId, type).then(res => {
            if(res.status === 200){
                if(res.data.status === 'success'){
                    alert(t('message-access-logged-success'));
                    
                }else{
                    alert(res.data.message);
                }
                
                setCardNumber('');
                setMember(null);
                setIsLoaded(false);
                
            }else{
                alert(t('message-access-logged-fail'));

            }
        })        
    }

    return <>
        <div className="flex flex-col justify-center">

            {!isLoaded && (
                <>
                    <h1 className="text-3xl font-extrabold my-3">{t('access-add')}</h1>
                    <p className="text-center my-3">{t('access-add-tip')}</p>

                    <div className="form-control my-3">
                        <label htmlFor="card_number">
                            <span className="label-text">{t('card-number')}</span>
                        </label>
                        <input type="text" name="card_number" id="card_number" className="input input-bordered" autoFocus={true} placeholder="Card Number" value={cardNumber} onChange={(e) => setCardNumber(e.target.value)} onKeyDown={(e) => { if (e.key === 'Enter') handleFind() }} />
                    </div>

                    <button className="btn btn-primary my-8" onClick={handleFind}>
                        {t('btn-find-member')}
                    </button>
                </>
            )}



            {(member && isLoaded) && (
                <div className="flex flex-col gap-y-3">

                    <h2 className="text-2xl font-bold text-center">{member.nickname}</h2>
                    {member.first_name && (
                        <p className="text-center">{member.first_name} {member.last_name}</p>
                    )}
                    <div className="form-control my-3">
                        <label htmlFor="branch">
                            <span className="label-text">{t('branch')}</span>
                        </label>
                        <select name="branch" id="branch" className="select select-bordered" value={branch} onChange={(e) => setBranch(e.target.value)}>
                            <option value="laem">Leam Chabang</option>
                            <option value="pattaya">Pattaya</option>
                        </select>
                    </div>
                    <div className="form-control my-3">
                        <label htmlFor="access_at">
                            <span className="label-text">{t('access-at')}</span>
                        </label>
                        <input type="datetime-local" name="access_at" id="access_at" className="input input-bordered" value={accessAt.toISOString().slice(0, 19)} onChange={(e) => setAccessAt(e.target.valueAsDate)} />
                    </div>

                    <button className="btn btn-primary my-8" onClick={() => { handleLogAccess('in') }}>
                        {t('btn-access-log-start')}
                    </button>
                    <button className="btn btn-secondary my-8" onClick={() => { handleLogAccess('out') }}>
                        {t('btn-access-log-stop')}
                    </button>
                </div>
            )}

        </div>
    </>
}

export default AddAccess;