import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addSell, getProduct } from "../../libs/api";
import {formatCurrency, getDefaultBranch} from "../../libs/utils";
import {useTranslation} from 'react-i18next';

function AddSell(props){    

    // the product id
    const {id} = useParams();

    const [branch, setBranch] = useState(getDefaultBranch());

    const [paymentMethod, setPaymentMethod] = useState('cash');

    const [product, setProduct] = useState({        
        name: '',
        price: 0,
        barcode: ''
    });

    const {t} = useTranslation();

    const [discount, setDiscount] = useState(0);

    const navigator = useNavigate();

    useEffect(() => {
        // fetch the product from the database
        getProduct(id).then(res => {
            console.log(res);
            if(res.status === 200){
                setProduct(res.data.data);
            }else{
                navigator('/sale');
            }            
        });
    }, []);

    const handleSave = async () => {
        // check if all value is not empty
        if (product.id === '' || product.name === '' || product.price === '' || product.barcode === '' || branch === '') {
            alert(t('alert-fill-all'));
            return;
        }
        // save sell to firebases        
        const sale = {
            branch: branch,
            discount: discount,
            payment_method: paymentMethod,
            items: [
                {
                    product_id: id,
                    quantity: 1
                }
            ]

        };
        addSell(sale).then(res=>{
            if(res.status === 200){
                alert(t('sale-add-success'));
                // clear form
                navigator('/sale');
            }else{
                alert(t('sale-add-fail'));
            }

        });             
    }

    return <>
    
        <div className="flex flex-col gap-y-6 justify-center">
            <h1 className="text-3xl font-extrabold my-8">{t('checkout')}</h1>
            <div>
                <dl>
                    <dt>{t('name')}</dt>
                    <dd>{product.name}</dd>
                    <dt>{t('price')}</dt>
                    <dd>{formatCurrency(product.unit_price)}</dd>
                    <dt>{t('barcode')}</dt>
                    <dd>{product.barcode}</dd>
                </dl>
            </div>
            <div className="form-control">
                <label htmlFor="branch">
                    <span className="label-text">{t('branch')}</span>
                </label>
                <select name="branch" id="branch" className="select select-bordered" value={branch} onChange={(e) => setBranch(e.target.value)}>
                    <option value="laem">{t('laem-chabang')}</option>
                    <option value="pattaya">{t('pattaya')}</option>
                </select>
            </div>
            <div className="form-control">
                <label htmlFor="payment">
                    <span className="label-text">{t('payment-method')}</span>
                </label>
                <select name="payment" id="payment" className="select select-bordered" value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
                    <option value="cash">{t('cash')}</option>
                    <option value="transfer">{t('transfer')}</option>
                    <option value="cc">{t('credit-card')}</option>
                </select>
            </div>
            <div className="form-control">
                <label htmlFor="discount">
                    <span className="label-text">{t('discount')}</span>
                </label>
                <input type="number" name="discount" id="discount" className="input input-bordered" placeholder="Price" value={discount} onChange={(e) => setDiscount(e.target.value)} />
            </div>
            

            <button className="btn btn-primary" onClick={handleSave}> {t('btn-add-sell')}</button>
        </div>        
    </>
}

export default AddSell;