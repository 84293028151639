import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/provider/AuthProvider";
import { addMember, searchMembers } from "../../libs/api";

function AddMember() {

    const [user, setUser] = useState({
        first_name: '',
        last_name: '',        
        nickname: '',
        gender: 'male',
        phone: '',
        join_at: new Date(),
        language: 'th'
    });

    const { t } = useTranslation();

    const navigator = useNavigate();

    /**
     * validate data change of join at field
     * @param {*} e 
     * @returns 
     */
    const handleFirstJoinChange = (e) => {
        // validate date is before today
        if (e.target.valueAsDate > new Date()) {
            alert(t('alert-date-can-not-after-today'));
            return;
        }

        setUser({ ...user, first_join: e.target.valueAsDate });
    }

    const handleSave = async () => {        
        // check if all value is not empty
        if (user.nickname === '' ||  user.join_at === '') {
            alert(t('alert-fill-all'));
            return;
        }
        
        // check if the mobile phone is already registered
        const result = await searchMembers(user.phone);
        if(result.status === 200 && result.data.length > 0){
            alert(t('alert-mobile-already-exists'));
            setUser({...user, mobile: ''});
            return;
        }

        console.log(user);

        addMember(user).then(res => {
            if(res.status === 200){
                alert(t('alert-member-added-success'));
                navigator(`/member/${res.data.id}`);
            }else{
                alert(t('alert-member-added-fail'));
            }
        });    
        
    }
    return (
        <>
            <div className="flex flex-col justify-center gap-y-6">

                <div className="form-control">
                    <label htmlFor="first_name" className="label">
                        <span className="label-text">{t('first-name')}</span>
                    </label>
                    <input type="text" name="first_name" id="first_name" className="input input-bordered" placeholder={t('first-name')} value={user.first_name} onChange={(e) => setUser({ ...user, first_name: e.target.value })} />
                </div>

                <div className="form-control">
                    <label htmlFor="last_name" className="label">
                        <span className="label-text">{t('last-name')}</span>
                    </label>
                    <input type="text" name="last_name" id="last_name" className="input input-bordered" placeholder={t('last-name')} value={user.last_name} onChange={(e) => setUser({ ...user, last_name: e.target.value })} />
                </div>

                <div className="form-control">
                    <label htmlFor="nickname" className="label">
                        <span className="label-text">{t('nickname')}*</span>
                    </label>
                    <input type="text" name="nickname" id="nickname" className="input input-bordered" placeholder={t('nickname')} value={user.nickname} onChange={(e) => setUser({ ...user, nickname: e.target.value })} />
                </div>

                <div className="form-control">
                    <label htmlFor="gender" className="label">
                        <span className="label-text">{t('gender')}</span>
                    </label>
                    <select name="gender" id="gender" className="select select-bordered" value={user.gender} onChange={(e) => setUser({...user, gender: e.target.value})}>
                        <option value="male">{t('male')}</option>
                        <option value="female">{t('female')}</option>
                    </select>
                </div>

                <div className="form-control">
                    <label htmlFor="mobile" className="label">
                        <span className="label-text">{t('mobile')}*</span>
                    </label>
                    <input type="tel" name="mobile" id="mobile" className="input input-bordered" placeholder={t('mobile')} value={user.phone} onChange={(e) => setUser({ ...user, phone: e.target.value })} />
                </div>

                <div className="form-control">
                    <label htmlFor="join_at" className="label">
                        <span className="label-text">{t('when-join')}*</span>
                    </label>
                    <input type="date" name="join_at" id="join_at" className="input input-bordered" placeholder="Join At" value={user.join_at?.toISOString()?.slice(0,10)} onChange={(e) => handleFirstJoinChange(e)} />
                </div>

                <div className="form-control">
                    <label htmlFor="language" className="label">
                        <span className="label-text">{t('language')}</span>
                    </label>
                    <select name="language" id="language" className="select select-bordered" value={user.language} onChange={(e) => setUser({ ...user, language: e.target.value })}>
                        <option value="th">{t('thai')}</option>
                        <option value="en">{t('english')}</option>
                        <option value="zh">{t('chinese')}</option>
                        <option value="jp">{t('japanese')}</option>
                        <option value="kr">{t('korean')}</option>
                        <option value="ru">{t('russian')}</option>
                        <option value="hi">{t('hindi')}</option>
                    </select>
                </div>

                <button type="button" className="btn btn-primary" onClick={handleSave}>{t('save')}</button>
            </div>
        </>
    )
}

export default AddMember;