import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react"

import { useTranslation } from 'react-i18next';

function Navbar(props) {

  const handleBack = () => {
    window.history.back();
  }

  const { t } = useTranslation();

  return (
    <>
      <div className="navbar bg-base-100">
        <div className="navbar-start">
          <div className="dropdown">
            <label tabIndex={0} className="btn btn-ghost btn-circle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
            </label>
            <ul
              tabIndex={0}
              className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
            >
              <li>
                <Link to={`/sale`}>{t('sales')}</Link>
              </li>

              <li>
                <Link to={`/member`}>{t('members')}</Link>
              </li>
              <li>
                <Link to={`/access`}>{t('access')}</Link>
              </li>
              <li>
                <Link to={`/product`}>{t('products')}</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="navbar-center">
          <Link to={props.link ?? `/dashboard`} className="btn btn-ghost normal-case text-xl">{props.title ?? `Universe Gym ERP`}</Link>
        </div>
        <div className="navbar-end">
          <Link to={`/settings`} className="btn btn-ghost btn-circle">
            <Icon icon="ic:baseline-settings" className="w-5 h-5" />
          </Link>

          <button className="btn btn-ghost btn-circle" onClick={handleBack}>
            <Icon icon="ri:arrow-go-back-fill" className="w-5 h-5" />
          </button>

        </div>
      </div>
    </>
  );
}

Navbar.propTypes = {
  title: PropTypes.string,
}
export default Navbar;
