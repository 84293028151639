import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getSells } from "../../libs/api";
import { PAGE_SIZE } from "../../libs/consts";
import { getBranchName, getDefaultBranch, getTimeFromSeconds } from "../../libs/utils";


function ListSell(props) {    
    
    const [sales, setSales] = useState([]);

    const [page, setPage] = useState(1);

    const [pageSize, setPageSize] = useState(PAGE_SIZE);

    const [pager, setPager] = useState({});

    const navigator = useNavigate();

    const [branch, setBranch] = useState(getDefaultBranch());

    useEffect(() => {
        getSells(branch, pageSize, page).then((data) => {
            console.log(data.data.data);
            setSales(data.data.data);
            setPager(data.data.pager);
        }
        );
    }, [page, branch]);

    return <>
        <div className="flex flex-col justify-center items-center">
            <h1 className="text-3xl font-extrabold my-3">{t('sales-last')}</h1>
            <p className="my-3">{t('sales-list-tip')}</p>
            <div className="overflow-x-auto w-full">
            <table className="w-full table table-zebra">
                <thead>
                    <tr>
                        <th>{t('branch')}</th>                        
                        <th>{t('total')}</th>
                        <th>{t('time')}</th>
                    </tr>
                </thead>
                <tbody>
                    {sales.map((item, index) => {                        
                        return <tr className="cursor-pointer" key={index} onClick={() => navigator(`/sale/${item.id}`)}>
                            <td>{getBranchName(item.branch)}</td>                            
                            <td>{item.total} ฿</td>
                            <td>{getTimeFromSeconds(item.created_at)}</td>
                        </tr>
                    })}
                </tbody>
            </table>
            </div>
            {sales.length > 0 && (
                <div className="flex flex-col my-3 justify-center items-center">
            <div className="my-3">
                <span>Current: {pager.perPage * (page -1)} - {pager.perPage * page} / Total: {pager.total}</span>
            </div>
            <div className="flex gap-x-8">
                <button className="btn btn-info" onClick={() => setPage(page - 1)}>{t('prev')}</button>
                <button className="btn btn-info" onClick={() => setPage(page + 1)}>{t('next')}</button>
            </div>            
      </div>
            )}
            
        </div>
    </>
}

export default ListSell;