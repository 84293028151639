import { createContext, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "@nanostores/react";
import { currentUser } from "../../stores/userStore";
import { useTranslation } from "react-i18next";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
        
    const auth = useAuthProvide();    
    
    
    return (
        <AuthContext.Provider value={auth}>
        {children}
        </AuthContext.Provider>
    );
};


export const useAuth = () => {
    return useContext(AuthContext);
};

export const useAuthProvide = () => {

    const user = useStore(currentUser);

    return {
        user,
    }
}

export const RequireLoggedIn = ({ children }) => {

    const user = useStore(currentUser);

    const navigator = useNavigate();
    const {t} = useTranslation();

    useEffect(() => {
        if(!user || !user.token){
            alert(t('alert-session-expired'));
            navigator('/login');
        }
    }, [user]);

    return children;
}