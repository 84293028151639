import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function InternetChecker(props){

    const [noInternet, setNoInternet] = useState(false);

    const [isOnline, setIsOnline] = useState(true);

    const {t} = useTranslation();

    useEffect(() => {
        window.navigator.onLine ? setIsOnline(true) : setIsOnline(false);

        window.addEventListener('online', () => {
            setIsOnline(true);
        });

        window.addEventListener('offline', () => {
            setIsOnline(false);
            setNoInternet(true);
        });

    }, []);
    return (
        <>
        {!isOnline && (
            <div className="fixed top-20 left-0 h-24 w-full z-50">
                <div className="bg-red-500 text-white text-center py-4">
                {t('alert-no-internet')}
                </div>
            </div>
        )}
        {
            (isOnline && noInternet) && (
                <div className="fixed top-20 left-0 h-24 w-full fade-in-out z-50">
                    <div className="bg-green-500 text-white text-center py-4">
                        {t('alert-back-online')}
                    </div>
                </div>
            )
        }
        </>
    )
}

export default InternetChecker;