import InternetChecker from "./InternetChecker";
import Navbar from "./Navbar";

function Layout(props) {
    return (
        <>
            <InternetChecker />
        
            <div className="min-h-screen">
                <Navbar title={props.title} />
                <div className="container overflow-x-hidden mx-auto px-6 md:px-12">
                    {props.children}
                </div>
            </div>        
        </>
    )
}

export default Layout;