import { useEffect, useState } from "react";
import { getAccessLogs } from "../../libs/api";
import { getBranchName, getDateTimeFromSeconds, getDefaultBranch } from "../../libs/utils";
import { PAGE_SIZE } from "../../libs/consts";
import { useTranslation } from "react-i18next";

function ListAccess(){
    const [access, setAccess] = useState([]);

    const [pageSize, setPageSize] = useState(PAGE_SIZE);

    const [page, setPage] = useState(1);

    const [branch, setBranch] = useState(getDefaultBranch());

    const [pager, setPager] = useState({});


    const {t} = useTranslation();

    useEffect(() => {
        // get access from firestore
        getAccessLogs(pageSize, page, branch).then(res => {
            console.log(res);
            if(res.status === 200){
                setAccess(res.data.data);
                setPager(res.data.pager);
            }
        });
    }, [page, branch]);

    return <>
    <div className="flex flex-col justify-center">
        <h1 className="text-3xl font-extrabold my-8">Access Logs</h1>
        <div className="my-3">
        <div className="form-control">
            <label htmlFor="branch">{t('branch')}</label>
            <select name="branch" id="branch" value={branch} onChange={(e) => setBranch(e.target.value)} className="select select-bordered">
                <option value="all">All</option>
                <option value="laem">{t('laem-chabang')}</option>
                <option value="pattaya">{t('pattaya')}</option>
            </select>            
        </div>
        </div>
        <div className="overflow-x-auto">
            <table className="w-full table table-zebra">
                <thead>
                    <tr>
                        <th>{t('branch')}</th>
                        <th>{t('name')}</th>
                        <th>{t('card')}</th>
                        <th>{t('access-at')}</th>
                        <th>{t('access-type')}</th>
                    </tr>
                </thead>
                <tbody>
                    {access.map((data, index) => {                        
                        return <tr key={index}>
                            <td>{getBranchName(data.branch)}</td>
                            <td>{data.name}</td>
                            <td>{data.card_number}</td>
                            <td>{data.created_at}</td>
                            <td>{t(data.access_type)}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
        
        <div className="flex flex-col my-3 justify-center items-center">
            <div className="my-3">
                <span>Current: {pager.perPage * (page -1)} - {pager.perPage * page} / Total: {pager.total}</span>
            </div>
            <div className="flex gap-x-8">
                <button className="btn btn-info" onClick={() => setPage(page - 1)}>{t('prev')}</button>
                <button className="btn btn-info" onClick={() => setPage(page + 1)}>{t('next')}</button>
            </div>            
        </div>
    </div>
    </>
}

export default ListAccess;