import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { getMember, updateMember } from "../../libs/api";

function EditMember() {

    const {id} = useParams();

    const [member, setMember] = useState({
        first_name: '',
        last_name: '',        
        nickname: '',
        gender: 'male',
        phone: '',
        join_at: new Date(),
        language: 'th'
    });

    const { t } = useTranslation();

    const navigator = useNavigate();

    
    

    const handleSave = async () => {        
        // check if all value is not empty
        if (member.nickname === '' || member.join_at === '') {
            alert(t('alert-fill-all'));
            return;
        }        

        // update the member data
        
        updateMember(id, member).then(res => {
            if(res.status === 200){
                alert(t('alert-member-updated-success'));
                navigator(`/member/${id}`);
            }else{
                alert(t('alert-member-updated-fail'));    
            }
        });
    }

    useEffect(() => {
        // fetch member data from firestore
        getMember(id).then(res => {
            console.log(res.data);
            if(res.data){            
                setMember(res.data);
            }else{
                navigator('/member');
            }
        });
    }, []);
    
    return (
        <>
            <div className="flex flex-col justify-center gap-y-6">

                <div className="form-control">
                    <label htmlFor="first_name" className="label">
                        <span className="label-text">{t('first-name')}</span>
                    </label>
                    <input type="text" name="first_name" id="first_name" className="input input-bordered" placeholder={t('first-name')} value={member.first_name} onChange={(e) => setMember({ ...member, first_name: e.target.value })} />
                </div>

                <div className="form-control">
                    <label htmlFor="last_name" className="label">
                        <span className="label-text">{t('last-name')}</span>
                    </label>
                    <input type="text" name="last_name" id="last_name" className="input input-bordered" placeholder={t('last-name')} value={member.last_name} onChange={(e) => setMember({ ...member, last_name: e.target.value })} />
                </div>

                <div className="form-control">
                    <label htmlFor="nickname" className="label">
                        <span className="label-text">{t('nickname')}*</span>
                    </label>
                    <input type="text" name="nickname" id="nickname" className="input input-bordered" placeholder={t('nickname')} value={member.nickname} onChange={(e) => setMember({ ...member, nickname: e.target.value })} />
                </div>

                <div className="form-control">
                    <label htmlFor="mobile" className="label">
                        <span className="label-text">{t('mobile')}*</span>
                    </label>
                    <input type="tel" name="mobile" id="mobile" className="input input-bordered" placeholder={t('mobile')} value={member.phone} onChange={(e) => setMember({ ...member, phone: e.target.value })} />
                </div>

                <div className="form-control">
                    <label htmlFor="join_at" className="label">
                        <span className="label-text">{t('when-join')}*</span>
                    </label>
                    <input type="text" name="join_at" id="join_at" className="input input-bordered" placeholder="Join At" value={member.join_at} readOnly={true} />
                    <label>
                        <span className="label-text">{t('non-changeable')}</span>
                    </label>
                </div>

                <div className="form-control">
                    <label htmlFor="language" className="label">
                        <span className="label-text">{t('language')}</span>
                    </label>
                    <select name="language" id="language" className="select select-bordered" value={member.language} onChange={(e) => setMember({ ...member, language: e.target.value })}>
                        <option value="th">{t('thai')}</option>
                        <option value="en">{t('english')}</option>
                        <option value="zh">{t('chinese')}</option>
                        <option value="jp">{t('japanese')}</option>
                        <option value="kr">{t('korean')}</option>
                        <option value="ru">{t('russian')}</option>
                        <option value="hi">{t('hindi')}</option>
                    </select>
                </div>

                <button type="button" className="btn btn-primary" onClick={handleSave}>{t('save')}</button>
            </div>
        </>
    )
}

export default EditMember;